<template>
  <div>
    <locations />

    <div class="page wrap-1200">
      <h3 class="subtitle">{{ $t("enter_fitting_info") }}</h3>
      <b-form @submit="onSubmit">
        <div class="bp-w440-lt">
          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >{{ $t("title.membership.height")
                  }}<small class="d-inline font-monospace fw-normal"
                    >(cm)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <input
                type="text"
                :placeholder="$t('text.membership.unit_omitted')"
                v-model="form.fit_user_height"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >{{ $t("title.membership.wrist_height")
                  }}<small class="d-inline font-monospace fw-normal"
                    >(cm)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <input
                type="text"
                :placeholder="$t('text.membership.unit_omitted')"
                v-model="form.fit_user_wrist"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >{{ $t("title.common.weight")
                  }}<small class="d-inline font-monospace fw-normal"
                    >(kg)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <input
                type="text"
                :placeholder="$t('text.membership.unit_omitted')"
                v-model="form.fit_user_weight"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell"
                ><span class="required">{{
                  $t("text.common.handicap")
                }}</span></label
              >
            </div>
            <div class="col-lg-9 d-table form-check-group">
              <div class="d-table-cell align-middle">
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="1"
                    id="handicap1"
                    v-model="form.fit_user_handicap"
                  />
                  <label class="form-check-label" for="handicap1">-79 </label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="2"
                    id="handicap2"
                    v-model="form.fit_user_handicap"
                  />
                  <label class="form-check-label" for="handicap2">80-89</label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="3"
                    id="handicap3"
                    v-model="form.fit_user_handicap"
                  />
                  <label class="form-check-label" for="handicap3">90-99</label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="4"
                    id="handicap4"
                    v-model="form.fit_user_handicap"
                  />
                  <label class="form-check-label" for="handicap4">+100</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >{{ $t("text.membership.avg_distance")
                  }}<small class="d-inline font-monospace fw-normal"
                    >(m)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9 d-table form-check-group">
              <div class="d-table-cell align-middle">
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="1"
                    id="driver1"
                    v-model="form.fit_user_driver"
                  />
                  <label class="form-check-label" for="driver1">+240 </label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="2"
                    id="driver2"
                    v-model="form.fit_user_driver"
                  />
                  <label class="form-check-label" for="driver2">220-240 </label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="3"
                    id="driver3"
                    v-model="form.fit_user_driver"
                  />
                  <label class="form-check-label" for="driver3">
                    200-220
                  </label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="4"
                    id="driver4"
                    v-model="form.fit_user_driver"
                  />
                  <label class="form-check-label" for="driver4">
                    180-200
                  </label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="5"
                    id="driver5"
                    v-model="form.fit_user_driver"
                  />
                  <label class="form-check-label" for="driver5"> -179 </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required">{{
                  $t("title.membership.club_important")
                }}</span>
              </label>
            </div>
            <div class="col-lg-9 d-table form-check-group">
              <div class="d-table-cell align-middle">
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="1"
                    id="important1"
                    v-model="form.fit_user_important"
                  />
                  <label class="form-check-label" for="important1">
                    {{ $t("text.common.sense_of_shot") }}
                  </label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="2"
                    id="important2"
                    v-model="form.fit_user_important"
                  />
                  <label class="form-check-label" for="important2">{{
                    $t("text.common.sense_of_direction")
                  }}</label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="3"
                    id="important3"
                    v-model="form.fit_user_important"
                  />
                  <label class="form-check-label" for="important3">{{
                    $t("text.common.sense_of_distance")
                  }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required">{{
                  $t("title.membership.preferred_shaft")
                }}</span>
              </label>
            </div>
            <div class="col-lg-9">
              <select aria-label="강도선택" v-model="form.fit_user_use_shaft">
                <option value="">
                  {{ $t("filter.common.shaft_flex") }}
                </option>
                <option
                  v-for="(val, idx) in ['R', 'SR', 'S', 'L']"
                  :key="'fit_user_use_shaft' + val"
                  :value="idx + 1"
                >
                  {{ val }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >{{ $t("filter.membership.hand_size")
                  }}<small class="d-inline font-monospace fw-normal"
                    >(cm)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <div class="row">
                <div class="col-sm-6 gx-0 gx-sm-2 ps-0">
                  <select
                    name="fieldunit"
                    id="fieldunit"
                    v-model="form.fit_user_glove"
                    requried
                  >
                    <option value="">
                      {{ $t("filter.membership.hand_size") }}
                    </option>
                    <option
                      v-for="(val, idx) in Array.from(
                        { length: 15 },
                        (x, i) => i + 14
                      )"
                      :key="'fit_user_glove' + idx"
                      :value="val"
                    >
                      {{ val }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-6 gx-0 gx-sm-2 pe-0 mt-2 mt-sm-0">
                  <select v-model="form.fit_user_finger" requried>
                    <option value="">
                      {{ $t("filter.membership.finger_length") }}
                    </option>
                    <option
                      v-for="(val, idx) in Array.from(
                        { length: 15 },
                        (x, i) => i + 0.5
                      )"
                      :key="'fit_user_finger' + idx"
                      :value="val"
                    >
                      {{ val }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >{{ $t("title.membership.bust")
                  }}<small class="d-inline font-monospace fw-normal"
                    >(cm)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <input
                type="text"
                :placeholder="$t('text.membership.unit_omitted')"
                v-model="form.fit_user_shirt"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >{{ $t("title.membership.waist_size")
                  }}<small class="d-inline font-monospace fw-normal"
                    >(Inch)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <input
                type="text"
                :placeholder="$t('text.membership.unit_omitted')"
                v-model="form.fit_user_pants"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required"
                  >{{ $t("title.membership.shoe_size")
                  }}<small class="d-inline font-monospace fw-normal"
                    >(mm)</small
                  ></span
                >
              </label>
            </div>
            <div class="col-lg-9">
              <input
                type="text"
                :placeholder="$t('text.membership.unit_omitted')"
                v-model="form.fit_user_shoes"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span class="required">{{
                  $t("title.membership.my_trouble")
                }}</span>
              </label>
            </div>
            <div class="col-lg-9">
              <textarea
                placeholder=""
                cols="30"
                rows="3"
                v-model="form.fit_user_trouble"
              ></textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span>{{ $t("title.membership.equipment_owned") }}</span>
              </label>
            </div>
            <div class="col-lg-9 m-0 p-0">
              <div class="row border-bottom">
                <div class="col-lg-3 d-table border-bottom border-lg-0">
                  <label class="d-table-cell">
                    <span class="text-gray">{{
                      $t("text.common.driver")
                    }}</span>
                  </label>
                </div>
                <div class="col-lg-9 d-table">
                  <div class="row">
                    <div class="col-sm-6 gx-0 gx-sm-2 ps-0">
                      <input
                        type="text"
                        placeholder=""
                        v-model="form.fit_user_use_driver"
                        required
                      />
                      <!-- <select>
                        <option>브랜드</option>
                        <option>타이틀리스트</option>
                      </select> -->
                    </div>
                    <div class="col-sm-6 gx-0 gx-sm-2 pe-0 mt-2 mt-sm-0">
                      <select v-model="form.fit_user_use_driver_date" requried>
                        <option value="" disabled>
                          {{ $t("text.membership.year_purchase") }}
                        </option>
                        <option
                          v-for="(val, idx) in Array.from(
                            { length: 20 },
                            (x, i) => $dayjs(new Date()).format('YYYY') - i
                          )"
                          :key="'fit_user_use_driver_date' + idx"
                          :value="val"
                        >
                          {{ val }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row border-bottom">
                <div class="col-lg-3 d-table border-bottom border-lg-0">
                  <label class="d-table-cell">
                    <span class="text-gray">{{ $t("text.common.wood") }}</span>
                  </label>
                </div>
                <div class="col-lg-9 d-table">
                  <div class="row">
                    <div class="col-sm-6 gx-0 gx-sm-2 ps-0">
                      <input
                        type="text"
                        placeholder=""
                        v-model="form.fit_user_use_wood"
                        required
                      />
                    </div>
                    <div class="col-sm-6 gx-0 gx-sm-2 pe-0 mt-2 mt-sm-0">
                      <select v-model="form.fit_user_use_wood_date" requried>
                        <option value="" disabled>
                          {{ $t("text.membership.year_purchase") }}
                        </option>
                        <option
                          v-for="(val, idx) in Array.from(
                            { length: 20 },
                            (x, i) => $dayjs(new Date()).format('YYYY') - i
                          )"
                          :key="'fit_user_use_wood_date' + idx"
                          :value="val"
                        >
                          {{ val }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row border-bottom">
                <div class="col-lg-3 d-table border-bottom border-lg-0">
                  <label class="d-table-cell">
                    <span class="text-gray">{{ $t("text.common.iron") }}</span>
                  </label>
                </div>
                <div class="col-lg-9 d-table">
                  <div class="row">
                    <div class="col-sm-6 gx-0 gx-sm-2 ps-0">
                      <input
                        type="text"
                        placeholder=""
                        v-model="form.fit_user_use_iron"
                        required
                      />
                    </div>
                    <div class="col-sm-6 gx-0 gx-sm-2 pe-0 mt-2 mt-sm-0">
                      <select v-model="form.fit_user_use_iron_date" requried>
                        <option value="" disabled>
                          {{ $t("text.membership.year_purchase") }}
                        </option>
                        <option
                          v-for="(val, idx) in Array.from(
                            { length: 20 },
                            (x, i) => $dayjs(new Date()).format('YYYY') - i
                          )"
                          :key="'fit_user_use_iron_date' + idx"
                          :value="val"
                        >
                          {{ val }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 d-table border-bottom border-lg-0">
                  <label class="d-table-cell">
                    <span class="text-gray">{{
                      $t("text.common.putter")
                    }}</span>
                  </label>
                </div>
                <div class="col-lg-9 d-table">
                  <div class="row">
                    <div class="col-sm-6 gx-0 gx-sm-2 ps-0">
                      <input
                        type="text"
                        placeholder=""
                        v-model="form.fit_user_use_putter"
                        required
                      />
                    </div>
                    <div class="col-sm-6 gx-0 gx-sm-2 pe-0 mt-2 mt-sm-0">
                      <select v-model="form.fit_user_use_putter_date" requried>
                        <option value="" disabled>
                          {{ $t("text.membership.year_purchase") }}
                        </option>
                        <option
                          v-for="(val, idx) in Array.from(
                            { length: 20 },
                            (x, i) => $dayjs(new Date()).format('YYYY') - i
                          )"
                          :key="'fit_user_use_putter_date' + idx"
                          :value="val"
                        >
                          {{ val }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 bg-gray d-table">
              <label class="d-table-cell">
                <span>{{ $t("text.membership.check_progress") }}</span>
              </label>
            </div>
            <div class="col-lg-9 d-table d-flex align-items-center">
              <div class="d-flex align-items-center">
                <input
                  class="form-check-input flex-shrink-0"
                  type="checkbox"
                  true-value="1"
                  false-value="0"
                  id="email_cd"
                  v-model="form.email_cd"
                />
                <label for="email_cd" class="pointer text-black"
                  >{{
                    $t("text.membership.receive_fitting_progress_email")
                  }}}}</label
                >
              </div>
            </div>
          </div>

          <div class="agree-box px-0 px-sm-3">
            <div class="form-check">
              <input
                type="checkbox"
                id="agree"
                class="form-check-input custom-checkbox"
                v-model="form.agree"
              />
              <label class="form-check-label text-gray" for="agree">
                {{ $t("text.membership.agree_my_personal_information") }}
              </label>
            </div>
          </div>

          <div class="btn-bottom-wrapper">
            <b-button
              size="xl"
              variant="outline-primary"
              aria-label="Cancel"
              @click="
                $router.push({
                  name: 'FittingSwingList',
                })
              "
              >취소</b-button
            >
            <b-button
              type="submit"
              size="xl"
              variant="secondary"
              :aria-label="$t('button.common.write')"
              >{{ $t("button.common.write") }}</b-button
            >
            <b-button
              type="submit"
              size="xl"
              variant="primary"
              :aria-label="$t('button.membership.algorithm_recommendations')"
              @click="form.is_algorithm = true"
              >{{ $t("button.membership.algorithm_recommendations") }}</b-button
            >
          </div>
        </div>
        <!-- //bp-w440-lt -->
      </b-form>
    </div>
  </div>
</template>

<script>
import locations from "@/components/Location";
import ax from "@/api/fitting";
import "@/filter/common";
import _ from "lodash";
export default {
  name: "FittingRequestWrite",
  data() {
    return {
      form: {
        fit_user_height: "",
        fit_user_wrist: "",
        fit_user_weight: "",
        fit_user_handicap: "",
        fit_user_driver: "",
        fit_user_important: "",
        fit_user_use_shaft: "",
        fit_user_glove: "",
        fit_user_finger: "",
        fit_user_shirt: "",
        fit_user_pants: "",
        fit_user_shoes: "",
        fit_user_trouble: "",
        fit_user_use_driver: "",
        fit_user_use_driver_date: "",
        fit_user_use_wood: "",
        fit_user_use_wood_date: "",
        fit_user_use_iron: "",
        fit_user_use_iron_date: "",
        fit_user_use_putter: "",
        fit_user_use_putter_date: "",
        sms_cd: "0",
        email_cd: "0",
        is_algorithm: false,
      },
      fittingno: this.$route.params.fittingno || 0,
      fittingreqno: this.$route.params.fittingreqno || 0,
    };
  },
  components: {
    locations,
  },

  created() {
    this.get_fittingreqinfo();
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      ax.post_fittingrequestwrite(this.form, this.fittingno, (flag, data) => {
        if (flag) {
          alert(data.message);

          this.$router.push({
            name: "FittingSwingList",
          });
        } else {
          alert(data.message);
          return false;
        }
      });
    },

    get_fittingreqinfo() {
      {
        ax.get_fittingreqinfo((flag, data) => {
          if (flag) {
            for (let key in this.form) {
              if (data.fittingreqinfo[key])
                this.form[key] = data.fittingreqinfo[key];
            }

            this.form.fit_user_use_driver_date = this.$dayjs(
              data.fittingreqinfo.fit_user_use_driver_date
            ).format("YYYY");
            this.form.fit_user_use_wood_date = this.$dayjs(
              data.fittingreqinfo.fit_user_use_wood_date
            ).format("YYYY");
            this.form.fit_user_use_iron_date = this.$dayjs(
              data.fittingreqinfo.fit_user_use_iron_date
            ).format("YYYY");
            this.form.fit_user_use_putter_date = this.$dayjs(
              data.fittingreqinfo.fit_user_use_putter_date
            ).format("YYYY");

            console.log("this.form", this.form);
          } else {
            alert(data.message);
          }
        });
      }
    },
  },
};
</script>
