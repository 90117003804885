<template>
  <div
    class="tab-pane fade show active"
    id="pills-2"
    role="tabpanel"
    aria-labelledby="pills-2-tab"
  >
    <h3 class="subtitle mt-lg-4">
      <span class="mb-2 icon badge bg-black text-capitalize">
        {{ tournament_type(tournamentinfo.tourtype_fk) }} </span
      ><br />
      {{ tournamentinfo.title }}
    </h3>
    <p class="info">{{ tournamentinfo.description }}</p>

    <div class="row row-cols-1 row-cols-md-auto gy-2 text-date fs-14 lh-sm">
      <div class="col">
        <!-- 서비스 시간 -->

        {{
          tournamentinfo.timezone_startdate &&
          tournamentinfo.timezone_startdate.timezone_common
            | dateformat("YYYY.MM.DD")
        }}
        ~
        {{
          tournamentinfo.timezone_startdate &&
          tournamentinfo.timezone_enddate.timezone_common
            | dateformat("YYYY.MM.DD")
        }}
      </div>
    </div>
    <b-form
      @submit="onSubmit"
      class="filter-group row row-cols-1 row-cols-md-4 g-3 mt-2"
    >
      <div class="col">
        <div class="input-group">
          <select
            class="form-select"
            aria-label="Select one"
            name="gender"
            v-model="searchData.gender"
          >
            <option value="0" :selected="searchData.gender === 0">
              {{ $t("filter.common.total") }}
            </option>
            <option value="1" :selected="searchData.gender === 1">
              {{ $t("filter.common.male") }}
            </option>
            <option value="2" :selected="searchData.gender === 2">
              {{ $t("filter.common.female") }}
            </option>
          </select>
          <button
            class="btn btn-primary btn-md icon-search"
            type="submit"
          ></button>
        </div>
      </div>
      <div class="col flex-fill">
        <ul class="tab-sub">
          <li>
            <a
              @click="change_type(1)"
              :class="{ on: searchData.scoretype == 1 }"
              >{{ $t("button.common.stroke") }}</a
            >
          </li>
          <li>
            <a
              @click="change_type(2)"
              :class="{ on: searchData.scoretype == 2 }"
              >{{ $t("button.common.stroke_handicap") }}</a
            >
          </li>
          <li>
            <a
              @click="change_type(3)"
              :class="{ on: searchData.scoretype == 3 }"
              >{{ $t("button.common.new_perio") }}</a
            >
          </li>
          <li>
            <a
              @click="change_type(4)"
              :class="{ on: searchData.scoretype == 4 }"
              >{{ $t("button.common.net_score") }}</a
            >
          </li>
        </ul>
      </div>
    </b-form>

    <table class="table board">
      <thead>
        <tr v-if="searchData.scoretype === 1">
          <th class="d-lg-table-cell">
            {{ $t("title.common.ranking") }}
          </th>
          <th class="d-lg-table-cell">
            {{ $t("title.common.nickname") }}
          </th>
          <th class="d-none d-lg-table-cell">
            {{ $t("title.common.location") }}
          </th>
          <th class="d-none d-lg-table-cell">
            {{ $t("title.common.course") }}
          </th>
          <th class="d-none d-sm-table-cell">
            {{ $t("title.common.stroke_score") }}
          </th>
          <th class="d-none d-lg-table-cell">
            {{ $t("title.common.no_of_play") }}
          </th>
          <th class="th-arrow"></th>
        </tr>
        <tr v-else-if="searchData.scoretype === 2">
          <th class="d-lg-table-cell">
            {{ $t("title.common.ranking") }}
          </th>
          <th class="d-lg-table-cell">
            {{ $t("title.common.nickname") }}
          </th>
          <th class="d-none d-lg-table-cell">
            {{ $t("title.common.location") }}
          </th>
          <th class="d-none d-lg-table-cell">
            {{ $t("title.common.course") }}
          </th>
          <th class="d-none d-sm-table-cell">
            {{ $t("title.common.low_net_score") }} /
            {{ $t("title.common.handi_score") }}
          </th>
          <th class="d-none d-lg-table-cell">
            {{ $t("title.common.no_of_play") }}
          </th>
          <!-- <th class="d-none d-lg-table-cell">
              Final Score<br />(Correction value)
            </th> -->
          <th class="th-arrow"></th>
        </tr>
        <tr v-else-if="searchData.scoretype === 3">
          <th class="d-lg-table-cell">
            {{ $t("title.common.ranking") }}
          </th>
          <th class="d-lg-table-cell">
            {{ $t("title.common.nickname") }}
          </th>
          <th class="d-none d-lg-table-cell">
            {{ $t("title.common.location") }}
          </th>
          <th class="d-none d-lg-table-cell">
            {{ $t("title.common.course") }}
          </th>
          <th class="d-none d-sm-table-cell">
            {{ $t("title.common.new_perio_score") }}
          </th>
          <th class="d-none d-lg-table-cell">
            {{ $t("title.common.no_of_play") }}
          </th>
          <!-- <th class="d-none d-lg-table-cell">
              Final Score<br />(Correction value)
            </th> -->
          <th class="th-arrow"></th>
        </tr>
        <tr v-else-if="searchData.scoretype === 4">
          <th class="d-lg-table-cell">
            {{ $t("title.common.ranking") }}
          </th>
          <th class="d-lg-table-cell">
            {{ $t("title.common.nickname") }}
          </th>
          <th class="d-none d-lg-table-cell">
            {{ $t("title.common.location") }}
          </th>
          <th class="d-none d-lg-table-cell">
            {{ $t("title.common.course") }}
          </th>
          <th class="d-none d-sm-table-cell">
            {{ $t("title.common.net_score") }}
          </th>
          <th class="d-none d-lg-table-cell">
            {{ $t("title.common.no_of_play") }}
          </th>
          <!-- <th class="d-none d-lg-table-cell">
              Final Score<br />(Correction value)
            </th> -->
          <th class="th-arrow"></th>
        </tr>
      </thead>

      <tbody v-if="total_cnt > 0 && searchData.scoretype === 1">
        <template v-for="(item, index) in items">
          <tr :key="index">
            <td class="d-lg-table-cell">
              {{ item.tournamentrankinfo.rank }}
            </td>
            <td class="d-lg-table-cell">
              <MemberInfo
                :nickname="item.tournamentrankinfo.nickname"
                :playerno="item.tournamentrankinfo.playerno"
              />
            </td>
            <td class="d-none d-lg-table-cell shop-name">
              {{
                item.tournamentrankinfo.shopinfo &&
                item.tournamentrankinfo.shopinfo.shopname
              }}
            </td>
            <td class="d-none d-lg-table-cell">
              <template v-for="(course, index_n) in item.coursescore">
                <p :key="index_n + 'l1'">
                  {{ course.coursename }}
                </p>
              </template>
            </td>
            <td class="d-none d-sm-table-cell">
              <template v-for="(course, index_n) in item.coursescore">
                <p :key="index_n + 'l1'">
                  {{ course.strokescore }}
                </p>
              </template>
            </td>
            <td class="d-none d-lg-table-cell text-red">
              {{ item.tournamentrankinfo.entrycnt | comma }}
              / {{ item.tournamentrankinfo.entrylimit | transToValue("∞") }}
            </td>
            <td class="td-arrow" @click="toggle_tr(index)">
              <img
                src="/img/arrow_none_collapsed.png"
                :class="{ 'btn-toggle-arrow': view_hide === index }"
              />
            </td>
          </tr>
          <tr
            class="d-lg-none"
            :class="{ show: view_hide === index }"
            :key="index + 'r'"
            v-if="view_hide === index"
          >
            <td colspan="7">
              <div class="toggle-content">
                <div class="row">
                  <div class="col-6">
                    {{ $t("title.common.location") }}
                  </div>
                  <div class="col-6 shop-name">
                    {{
                      item.tournamentrankinfo.shopinfo &&
                      item.tournamentrankinfo.shopinfo.shopname
                    }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    {{ $t("title.common.course") }}
                  </div>
                  <div class="col-6">
                    <template v-for="(course, index_nm) in item.coursescore">
                      {{ index_nm > 0 ? " / " : "" }}
                      <span :key="index_nm + 'm'"
                        >{{ course.coursename }}
                      </span>
                    </template>
                  </div>
                </div>

                <div class="row d-sm-none">
                  <div class="col-6">{{ $t("title.common.stroke_score") }}</div>
                  <div class="col-6">
                    <template v-for="(course, index_nm) in item.coursescore">
                      {{ index_nm > 0 ? " / " : "" }}
                      <span :key="index_nm + 'm'">
                        {{ course.strokescore }}</span
                      >
                    </template>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">{{ $t("title.common.no_of_play") }}</div>
                  <div class="col-6 text-red">
                    {{ item.tournamentrankinfo.entrycnt | comma }}
                    /
                    {{ item.tournamentrankinfo.entrylimit | transToValue("∞") }}
                  </div>
                </div>
                <!-- <div class="row">
                    <div class="col-6">Final Score<br />(Correction value)</div>
                    <div class="col-6">
                      {{ item.tournamentrankinfo.newperiolastscore | comma }}
                    </div>
                  </div> -->
              </div>
            </td>
          </tr>
        </template>
      </tbody>
      <tbody v-else-if="total_cnt > 0 && searchData.scoretype === 2">
        <template v-for="(item, index) in items">
          <tr :key="index">
            <td class="d-lg-table-cell">
              {{ item.tournamentrankinfo.rank }}
            </td>
            <td class="d-lg-table-cell">
              <MemberInfo
                :nickname="item.tournamentrankinfo.nickname"
                :playerno="item.tournamentrankinfo.playerno"
              />
            </td>
            <td class="d-none d-lg-table-cell shop-name">
              {{
                item.tournamentrankinfo.shopinfo &&
                item.tournamentrankinfo.shopinfo.shopname
              }}
            </td>
            <td class="d-none d-lg-table-cell">
              <template v-for="(course, index_n) in item.coursescore">
                <p :key="index_n + 'l1'">
                  {{ course.coursename }}
                </p>
              </template>
            </td>

            <td class="d-none d-sm-table-cell">
              <template v-for="(course, index_n) in item.coursescore">
                <p :key="index_n + 'l1'">
                  {{ course.low_net }} /
                  {{
                    Math.sign(course.min_score - course.low_net) > 0 ? "+" : ""
                  }}
                  {{ course.min_score - course.low_net }}
                </p>
              </template>
            </td>

            <td class="d-none d-lg-table-cell text-red">
              {{ item.tournamentrankinfo.entrycnt | comma }} /
              {{ item.tournamentrankinfo.entrylimit | transToValue("∞") }}
            </td>
            <!-- <td class="d-none d-lg-table-cell">
                {{ item.tournamentrankinfo.newperiolastscore | comma }}
              </td> -->
            <td class="td-arrow" @click="toggle_tr(index)">
              <img
                src="/img/arrow_none_collapsed.png"
                :class="{ 'btn-toggle-arrow': view_hide === index }"
              />
            </td>
          </tr>
          <tr
            class="d-lg-none"
            :class="{ show: view_hide === index }"
            :key="index + 'r'"
            v-if="view_hide === index"
          >
            <td colspan="7">
              <div class="toggle-content">
                <div class="row">
                  <div class="col-6">
                    {{ $t("title.common.location") }}
                  </div>
                  <div class="col-6 shop-name">
                    {{
                      item.tournamentrankinfo.shopinfo &&
                      item.tournamentrankinfo.shopinfo.shopname
                    }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    {{ $t("title.common.course") }}
                  </div>
                  <div class="col-6">
                    <template v-for="(course, index_n) in item.coursescore">
                      <p :key="index_n + 'l1'">
                        {{ course.coursename }}
                      </p>
                    </template>
                  </div>
                </div>
                <div class="row d-sm-none">
                  <div class="col-6">
                    {{ $t("title.common.low_net_score") }} /
                    {{ $t("title.common.handi_score") }}
                  </div>
                  <div class="col-6">
                    <template v-for="(course, index_n) in item.coursescore">
                      <p :key="index_n + 'l1'">
                        {{ course.low_net }} /
                        {{
                          Math.sign(course.min_score - course.low_net) > 0
                            ? "+"
                            : ""
                        }}
                        {{ course.min_score - course.low_net }}
                      </p>
                    </template>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">{{ $t("title.common.no_of_play") }}</div>
                  <div class="col-6 text-red">
                    {{ item.tournamentrankinfo.entrycnt | comma }} /
                    {{ item.tournamentrankinfo.entrylimit | transToValue("∞") }}
                  </div>
                </div>
                <!-- <div class="row">
                    <div class="col-6">Final Score<br />(Correction value)</div>
                    <div class="col-6">
                      {{ item.tournamentrankinfo.newperiolastscore | comma }}
                    </div>
                  </div> -->
              </div>
            </td>
          </tr>
        </template>
      </tbody>
      <tbody v-else-if="total_cnt > 0 && searchData.scoretype === 3">
        <template v-for="(item, index) in items">
          <tr :key="index">
            <td class="d-lg-table-cell">
              {{ item.tournamentrankinfo.rank }}
            </td>
            <td class="d-lg-table-cell">
              <MemberInfo
                :nickname="item.tournamentrankinfo.nickname"
                :playerno="item.tournamentrankinfo.playerno"
              />
            </td>
            <td class="d-none d-lg-table-cell shop-name">
              {{
                item.tournamentrankinfo.shopinfo &&
                item.tournamentrankinfo.shopinfo.shopname
              }}
            </td>
            <td class="d-none d-lg-table-cell">
              <template v-for="(course, index_n) in item.coursescore">
                <p :key="index_n + 'l1'">{{ course.coursename }}</p>
              </template>
            </td>
            <td class="d-none d-sm-table-cell">
              <template v-for="(course, index_n) in item.coursescore">
                <p :key="index_n + 'l2'">
                  {{ course.newperioscore }}
                </p>
              </template>
            </td>
            <td class="d-none d-lg-table-cell text-red">
              {{ item.tournamentrankinfo.entrycnt | comma }} /
              {{ item.tournamentrankinfo.entrylimit | transToValue("∞") }}
            </td>
            <!-- <td class="d-none d-lg-table-cell">
                {{ item.tournamentrankinfo.newperiolastscore | comma }}
              </td> -->
            <td class="td-arrow" @click="toggle_tr(index)">
              <img
                src="/img/arrow_none_collapsed.png"
                :class="{ 'btn-toggle-arrow': view_hide === index }"
              />
            </td>
          </tr>
          <tr
            class="d-lg-none"
            :class="{ show: view_hide === index }"
            :key="index + 'r'"
            v-if="view_hide === index"
          >
            <td colspan="7">
              <div class="toggle-content">
                <div class="row">
                  <div class="col-6">
                    {{ $t("title.common.location") }}
                  </div>
                  <div class="col-6 shop-name">
                    {{
                      item.tournamentrankinfo.shopinfo &&
                      item.tournamentrankinfo.shopinfo.shopname
                    }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    {{ $t("title.common.course") }}
                  </div>
                  <div class="col-6">
                    <template v-for="(course, index_nm) in item.coursescore">
                      {{ index_nm > 0 ? " / " : "" }}
                      <span :key="index_nm + 'm'">
                        {{ course.coursename }}
                      </span>
                    </template>
                  </div>
                </div>
                <div class="row d-sm-none">
                  <div class="col-6">
                    {{ $t("title.common.new_perio_score") }}
                  </div>
                  <div class="col-6">
                    <template v-for="(course, index_n) in item.coursescore">
                      <span :key="index_n + 'l2'">
                        {{ course.newperioscore }}
                      </span>
                    </template>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">{{ $t("title.common.no_of_play") }}</div>
                  <div class="col-6 text-red">
                    {{ item.tournamentrankinfo.entrycnt | comma }} /
                    {{ item.tournamentrankinfo.entrylimit | transToValue("∞") }}
                  </div>
                </div>
                <!-- <div class="row">
                    <div class="col-6">Final Score<br />(Correction value)</div>
                    <div class="col-6">
                      {{ item.tournamentrankinfo.newperiolastscore | comma }}
                    </div>
                  </div> -->
              </div>
            </td>
          </tr>
        </template>
      </tbody>
      <tbody v-else-if="total_cnt > 0 && searchData.scoretype === 4">
        <template v-for="(item, index) in items">
          <tr :key="index">
            <td class="d-lg-table-cell">
              {{ item.tournamentrankinfo.rank }}
            </td>
            <td class="d-lg-table-cell">
              <MemberInfo
                :nickname="item.tournamentrankinfo.nickname"
                :playerno="item.tournamentrankinfo.playerno"
              />
            </td>
            <td class="d-none d-lg-table-cell shop-name">
              {{
                item.tournamentrankinfo.shopinfo &&
                item.tournamentrankinfo.shopinfo.shopname
              }}
            </td>
            <td class="d-none d-lg-table-cell">
              <template v-for="(course, index_n) in item.coursescore">
                <p :key="index_n + 'l1'">
                  {{ course.coursename }}
                </p>
              </template>
            </td>

            <td class="d-none d-sm-table-cell">
              <template v-for="(course, index_n) in item.coursescore">
                <p :key="index_n + 'l1'">
                  {{ (course.avghandicapscore - item.base_par) | toFixed }}
                </p>
              </template>
            </td>

            <td class="d-none d-lg-table-cell text-red">
              {{ item.tournamentrankinfo.entrycnt | comma }} /
              {{ item.tournamentrankinfo.entrylimit | transToValue("∞") }}
            </td>
            <!-- <td class="d-none d-lg-table-cell">
                {{ item.tournamentrankinfo.newperiolastscore | comma }}
              </td> -->
            <td class="td-arrow" @click="toggle_tr(index)">
              <img
                src="/img/arrow_none_collapsed.png"
                :class="{ 'btn-toggle-arrow': view_hide === index }"
              />
            </td>
          </tr>
          <tr
            class="d-lg-none"
            :class="{ show: view_hide === index }"
            :key="index + 'r'"
            v-if="view_hide === index"
          >
            <td colspan="7">
              <div class="toggle-content">
                <div class="row">
                  <div class="col-6">
                    {{ $t("title.common.location") }}
                  </div>
                  <div class="col-6 shop-name">
                    {{
                      item.tournamentrankinfo.shopinfo &&
                      item.tournamentrankinfo.shopinfo.shopname
                    }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    {{ $t("title.common.course") }}
                  </div>
                  <div class="col-6">
                    <template v-for="(course, index_n) in item.coursescore">
                      <p :key="index_n + 'l1'">
                        {{ course.coursename }}
                      </p>
                    </template>
                  </div>
                </div>
                <div class="row d-sm-none">
                  <div class="col-6">
                    {{ $t("title.common.net_score") }}
                  </div>
                  <div class="col-6">
                    <template v-for="(course, index_n) in item.coursescore">
                      <p :key="index_n + 'l1'">
                        {{
                          (course.avghandicapscore - item.base_par) | toFixed
                        }}
                      </p>
                    </template>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">{{ $t("title.common.no_of_play") }}</div>
                  <div class="col-6 text-red">
                    {{ item.tournamentrankinfo.entrycnt | comma }} /
                    {{ item.tournamentrankinfo.entrylimit | transToValue("∞") }}
                  </div>
                </div>
                <!-- <div class="row">
                    <div class="col-6">Final Score<br />(Correction value)</div>
                    <div class="col-6">
                      {{ item.tournamentrankinfo.newperiolastscore | comma }}
                    </div>
                  </div> -->
              </div>
            </td>
          </tr>
        </template>
      </tbody>
      <tbody v-else>
        <tr>
          <td
            colspan="7"
            v-if="tournamentinfo.holecount > 9 || searchData.scoretype < 3"
          >
            {{ $t("text.common.no_data") }}
          </td>
          <td colspan="7" v-else>
            {{ $t("text.common.not_provided_9_hole_mode") }}
          </td>
        </tr>
      </tbody>
    </table>

    <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="page_total"
      v-model="searchData.pageno"
      use-router
      aria-label="Page navigation"
      v-if="page_total > 1"
      first-class="prev"
      prev-class="prev"
      next-class="next"
      last-class="next"
      hide-ellipsis
      limit="10"
    >
      <template #first-text>
        <i class="material-icons">keyboard_double_arrow_left</i>
      </template>
      <template #prev-text>
        <i class="material-icons">navigate_before</i>
      </template>
      <template #next-text>
        <i class="material-icons">navigate_next</i>
      </template>
      <template #last-text>
        <i class="material-icons">keyboard_double_arrow_right</i>
      </template>
      <template #page="{ page, active }">
        <a v-if="active">{{ page }}</a>
        <a v-else>{{ page }}</a>
      </template>
    </b-pagination-nav>
  </div>
</template>

<script>
import ax from "@/api/tournament";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "ShopTournamentRanking",

  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        tournamentno: this.$route.params.id || 0,
        scoretype:
          this.$route.query.scoretype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].scoretype) ||
          1,
        gender:
          this.$route.query.gender ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].gender) ||
          0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
      tournamentinfo: {},
    };
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_ownertournamentranklist();
    },
  },
  methods: {
    get_ownertournamentranklist() {
      ax.get_ownertournamentranklist(this.searchData, (flag, data) => {
        if (flag) {
          this.total_cnt = data.tournamentranklistcnt;
          this.page_total = Math.ceil(data.tournamentranklistcnt / this.rows);
          this.items = data.tournamentranklist;
          this.tournamentinfo = data.tournamentinfo;
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            scoretype: this.searchData.scoretype,
            gender: this.searchData.gender,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    change_type(type) {
      this.searchData.scoretype = type;
      this.searchData.pageno = 1;
      this.view_hide = null;
    },
  },
  created() {
    this.get_ownertournamentranklist();
  },
  watch: {
    // "$route.query": function () {
    //   this.searchData.pageno = parseInt(this.$route.query.page) || 1;
    //   this.get_eventwinningranklist();
    // },
    searchData: {
      handler: function (val, oldVal) {
        this.$store.commit("setSearchData", {
          [this.$route.name]: val,
        });
        this.get_ownertournamentranklist();
      },
      deep: true,
    },
  },
  mixins: [myMixin],
};
</script>
