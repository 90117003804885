<template>
  <div>
    <location />
    <div class="latest-tourn-wrapper">
      <a class="latest-tourn-info">
        <div>
          <router-link
            :to="{
              name: 'Condition',
              params: {
                id:
                  tournamenttop.tournamentinfo &&
                  tournamenttop.tournamentinfo.tournamentno,
              },
            }"
            class=""
          >
            <span class="badge rounded-pill">{{
              tournament_type(
                tournamenttop.tournamentinfo &&
                  tournamenttop.tournamentinfo.type
              )
            }}</span>
            <h3>
              {{
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.title
              }}
            </h3>
            <p class="info mb-5">
              {{
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.description
              }}
            </p>
            <h6 class="text-date">
              {{
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_startdate.timezone_common
                  | dateformat("YYYY.MM.DD")
              }}
              ~
              {{
                tournamenttop.tournamentinfo &&
                tournamenttop.tournamentinfo.timezone_enddate.timezone_common
                  | dateformat("YYYY.MM.DD")
              }}
            </h6>
          </router-link>
        </div>
      </a>
      <img
        :src="
          tournamenttop.tournamentinfo &&
          tournamenttop.tournamentinfo.tourbinary[0] &&
          tournamenttop.tournamentinfo.tourbinary[0].filepath | get_img
        "
      />
    </div>

    <div class="page wrap-1200">
      <b-form
        @submit="onSubmit"
        class="filter-group row row-cols-2 row-cols-sm-auto g-2 justify-content-end"
      >
        <div class="col">
          <select
            aria-label="Select Mini Game Type"
            name="type"
            v-model="searchData.tourstatus_fk"
          >
            <option value="0">{{ $t("filter.common.total") }}</option>
            <option value="1">{{ $t("text.common.ready") }}</option>
            <option value="2">{{ $t("text.common.playing") }}</option>
            <option value="3">{{ $t("text.common.completed") }}</option>
          </select>
        </div>
        <div class="col">
          <select
            aria-label="Select one"
            name="searchtype"
            v-model="searchData.tourtype_fk"
          >
            <option value="0">{{ $t("filter.common.total") }}</option>
            <option value="1">{{ $t("text.common.status_public") }}</option>
            <option value="2">
              {{ $t("text.common.status_invitational") }}
            </option>
            <!-- <option value="2">매장명</option> -->
            <!-- <option value="3">{{ $t("filter.common.course") }}</option> -->
          </select>
        </div>
        <div class="col-12">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="searchname"
              v-model="searchData.searchname"
              :placeholder="
                $t('alert.common.field_enter', {
                  field: $t('title.common.tournament'),
                })
              "
            />
            <button
              class="btn btn-primary btn-md icon-search"
              type="submit"
            ></button>
          </div>
        </div>
      </b-form>

      <div
        v-if="total_cnt > 0"
        class="tourn-card row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-xl-5 gy-5"
      >
        <div class="col" v-for="(item, index) in items" :key="'a' + index">
          <router-link
            :to="{
              name: 'Condition',
              params: {
                id: item.tournamentinfo.tournamentno,
              },
            }"
            class=""
          >
            <div class="card h-100">
              <div class="card-img-wrapper">
                <img
                  :src="
                    item.tournamentinfo.tourbinary[0] &&
                    item.tournamentinfo.tourbinary[0].filepath | get_img
                  "
                />
              </div>
              <div class="card-body">
                <span class="badge rounded-pill">{{
                  tournament_type(item.tournamentinfo.type)
                }}</span>
                <h5 class="card-title">{{ item.tournamentinfo.title }}</h5>
              </div>
              <div class="card-footer text-truncate text-date">
                <small
                  >{{
                    item.tournamentinfo.timezone_startdate.timezone_common
                      | dateformat("YYYY.MM.DD")
                  }}
                  ~
                  {{
                    item.tournamentinfo.timezone_enddate.timezone_common
                      | dateformat("YYYY.MM.DD")
                  }}</small
                >
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div v-else>
        <table class="table board toggle-xl">
          <tbody>
            <tr>
              <td colspan="4">{{ $t("text.common.no_data") }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/tournament";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "TournamentSchedule",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        tourstatus_fk:
          this.$route.query.tourstatus_fk ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].tourstatus_fk) ||
          0,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        tourtype_fk:
          this.$route.query.tourtype_fk ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].tourtype_fk) ||
          0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 9,
      items: [],
      tournamenttop: {},
      view_hide: null,
    };
  },

  methods: {
    get_tournamentlist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_tournamentlist(this.searchData, (flag, data) => {
        this.total_cnt = data.tournamentlistcnt;
        this.page_total = Math.ceil(data.tournamentlistcnt / this.rows);
        this.items = data.tournamentlist;
        this.tournamenttop = data.tournamenttop;
        if (flag) {
        } else {
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            searchname: this.searchData.searchname,
            searchtype: this.searchData.searchtype,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_tournamentlist();
  },
  watch: {
    "searchData.tourtype_fk": function () {
      this.searchData.pageno = 1;
      this.get_tournamentlist();
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_tournamentlist();
    },
    "searchData.tourstatus_fk": function () {
      this.searchData.pageno = 1;
      this.get_tournamentlist();
    },

    "searchData.pageno": function () {
      this.get_tournamentlist();
    },
  },
  mixins: [myMixin],
};
</script>
