<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <b-form
        @submit="onSubmit"
        class="filter-group row row-cols-2 row-cols-sm-auto g-2 justify-content-end"
      >
        <div class="col">
          <select
            aria-label="Select Mini Game Type"
            name="type"
            v-model="searchData.tourstatus_fk"
          >
            <option value="0">{{ $t("filter.common.total") }}</option>
            <option value="1">{{ $t("text.common.ready") }}</option>
            <option value="2">{{ $t("text.common.playing") }}</option>
            <option value="3">{{ $t("text.common.completed") }}</option>
          </select>
        </div>
        <div class="col">
          <select
            aria-label="Select one"
            name="searchtype"
            v-model="searchData.searchtype"
          >
            <option value="1">{{ $t("filter.common.tournament") }}</option>
            <!-- <option value="2">매장명</option> -->
            <option value="3">{{ $t("filter.common.course") }}</option>
          </select>
        </div>
        <div class="col-12">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="searchname"
              v-model="searchData.searchname"
            />
            <button
              class="btn btn-primary btn-md icon-search"
              type="submit"
            ></button>
          </div>
        </div>
      </b-form>

      <table class="table board toggle-xl">
        <thead>
          <tr>
            <th class="d-xl-table-cell">
              {{ $t("title.common.tournament") }}
            </th>
            <th class="d-none d-sm-table-cell">
              {{ $t("title.common.date") }}
            </th>
            <th class="d-none d-xl-table-cell">
              {{ $t("title.common.course") }}
            </th>
            <th class="d-xl-table-cell text-center">
              {{ $t("title.common.location") }}
            </th>
            <!-- <th class="d-none d-xl-table-cell">Point</th> -->
            <th class="d-none d-md-table-cell">
              {{ $t("title.common.status") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in items">
            <tr :key="index">
              <td class="d-xl-table-cell text-start">
                <router-link
                  :to="{
                    name: 'ShopCondition',
                    params: { id: item.tournamentinfo.tournamentno },
                    query: {
                      searchtype: searchData.searchtype,
                      searchname: searchData.searchname,
                    },
                  }"
                  class="d-inline-block pointer"
                >
                  <span class="fs-12 text-capitalize text-black">{{
                    tournament_type(item.tournamentinfo.type)
                  }}</span
                  ><br />
                  <span class="more">{{ item.tournamentinfo.title }}</span>
                </router-link>
              </td>
              <td class="w-1 d-none d-sm-table-cell text-date fs-14">
                <!-- 서비스 시간 -->
                <p>
                  {{
                    item.tournamentinfo.timezone_startdate.timezone_common
                      | dateformat("YYYY.MM.DD")
                  }}
                  ~
                  {{
                    item.tournamentinfo.timezone_enddate.timezone_common
                      | dateformat("YYYY.MM.DD")
                  }}
                </p>
              </td>
              <td class="d-none d-xl-table-cell">
                <template v-for="(coures, c_index) in item.tournamentcourse">
                  <p :key="c_index">{{ coures.coursename }}</p>
                </template>
              </td>
              <td class="d-xl-table-cell">
                <template v-for="(shop, s_index) in item.tournamentshop">
                  <p :key="s_index">{{ shop.shopname }}</p>
                </template>
              </td>
              <!-- <td class="d-none d-xl-table-cell text-cyan">
                {{ item.tournamentinfo.point | comma }}p
              </td> -->
              <td class="d-none d-md-table-cell">
                {{ tournament_status(item.tournamentinfo.status) }}
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="d-xl-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="4">
                <div class="toggle-content">
                  <div class="row d-sm-none">
                    <div class="col-4">{{ $t("title.common.date") }}</div>
                    <div class="col text-date">
                      <!-- 서비스 시간 -->
                      {{
                        item.tournamentinfo.timezone_startdate.timezone_common
                          | dateformat("YYYY.MM.DD")
                      }}
                      ~
                      {{
                        item.tournamentinfo.timezone_enddate.timezone_common
                          | dateformat("YYYY.MM.DD")
                      }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.course") }}</div>
                    <div class="col-8">
                      <template
                        v-for="(coures, c_index) in item.tournamentcourse"
                      >
                        <p :key="c_index">{{ coures.coursename }}</p>
                      </template>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-6">Point</div>
                    <div class="col-6 text-cyan">
                      {{ item.tournamentinfo.point | comma }}p
                    </div>
                  </div> -->
                  <div class="row d-md-none">
                    <div class="col-4">{{ $t("title.common.status") }}</div>
                    <div class="col-8">
                      {{ tournament_status(item.tournamentinfo.status) }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4">{{ $t("text.common.no_data") }}</td>
          </tr>
        </tbody>
      </table>

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/tournament";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "ShopTournamentSchedule",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        tourstatus_fk:
          this.$route.query.tourstatus_fk ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].tourstatus_fk) ||
          0,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          1,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
    };
  },

  methods: {
    get_ownertournamentlist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_ownertournamentlist(this.searchData, (flag, data) => {
        if (flag) {
          if (data.tournamentlistcnt <= 0) {
            this.data_yn = false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.tournamentlistcnt;
          this.page_total = Math.ceil(data.tournamentlistcnt / this.rows);
          this.items = data.tournamentlist;
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            searchname: this.searchData.searchname,
            searchtype: this.searchData.searchtype,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_ownertournamentlist();
  },
  watch: {
    "searchData.searchtype": function () {
      this.searchData.pageno = 1;
      this.get_ownertournamentlist();
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_ownertournamentlist();
    },
    "searchData.tourstatus_fk": function () {
      this.searchData.pageno = 1;
      this.get_ownertournamentlist();
    },

    "searchData.pageno": function () {
      this.get_ownertournamentlist();
    },
  },
  mixins: [myMixin],
};
</script>
